/*
 * Flights
 *
 * List of Flights
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { darken } from 'polished';
import { Header, Table, Segment, Form, Button, Icon, Responsive, Message, Tab } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { orderBy } from 'lodash';
import moment from 'moment';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import PlaceHolderCard from 'components/PlaceHolderCard';

import { makeSelectLoading, makeSelectError, makeSelectFlights } from './selectors';
import reducer from './reducer';
import saga from './saga';
import { loadFlights } from './actions';

import FlightRow from './FlightRow';
import flightSvg from './flight-icon.svg';

const FlightIcon = styled.img.attrs({ src: flightSvg })`
  .ui.header > img& {
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 10px;
    vertical-align: bottom;
  }
`;

const SearchTab = styled(Tab)`
  margin: 1rem 0;
  & > .ui.tabular.menu .active.item {
    padding-left: 18px;
    padding-right: 18px;
  }
  & > .ui.tabular.menu .active.item {
    background-color: #1b1c1d;
    color: #fff;
    border-color: #555;
  }
`;

const TabPane = styled(Tab.Pane)`
  &.ui.segment {
    padding: 0;
    border: none;
  }
`;

const SearchForm = styled(Form)`
  width: 100%;
  &.ui.form .field > label {
    color: #ffffff;
  }
  &.ui.form .fields {
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    & .wide.field {
      padding-left: 1em;
      padding-right: 1em;
      @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
        margin-bottom: 15px;
      }
    }
  }
`;

// const Divider = styled.div`
//   width: 50px;
//   text-align: center;
//   align-self: flex-end;
//   margin-bottom: 10px;

//   @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
//     display: flex;
//     width: 100%;
//     flex-basis: 100%;
//     align-items: center;

//     &::before,
//     &::after {
//       content: '';
//       flex-grow: 1;
//       background: rgba(255, 255, 255, 0.35);
//       height: 1px;
//       font-size: 0px;
//       line-height: 0px;
//       margin: 0px 15px;
//     }
//   }
// `;

const SubmitButton = styled(Button).attrs({
  type: 'submit',
  icon: true,
  labelPosition: 'right',
  // color: 'yellow',
})`
  &.ui.button {
    color: #000;
    background-color: #ffe900;
    padding: 12px 50px 12px 15px !important;
    margin-left: 1em;
    margin-right: 1em;
    &.labeled.icon > .icon {
      transition: background-color 0.1s ease;
      background-color: #ffe900;
    }

    &:hover {
      background-color: ${darken(0.03, '#ffe900')};
      &.labeled.icon > .icon {
        background-color: ${darken(0.03, '#ffe900')};
      }
    }
    &:active {
      background-color: ${darken(0.07, '#ffe900')};
      &.labeled.icon > .icon {
        background-color: ${darken(0.07, '#ffe900')};
      }
    }
  }
`;

const FlightsListHeader = styled.h3`
  margin: calc(2rem - 0.14285714em) 0 1rem;
  padding: 0 0;
  font-weight: 700;
  font-size: 1.28571429rem;
  line-height: 1.28571429em;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// const FlightsListSubheader = styled.div`
//   font-size: 1rem;
//   text-align: right;
//   display: block;
//   font-weight: 400;
//   padding: 0;
//   margin: 0;
//   line-height: 1.2em;
//   color: rgba(0, 0, 0, 0.6);

//   @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
//     font-size: 0.95rem;
//     width: 100%;
//     text-align: left;
//     margin-top: 10px;
//   }
// `;

const FlightsContainer = styled.div`
  clear: right;
`;
const FlightTable = styled(Table)`
  background-color: #ffffff !important;
  th {
    border-left: none !important;
    color: #777777 !important;
  }
`;
const SortableTableHeaderCell = styled(Table.HeaderCell)`
  &.sorted {
    color: #f6a724 !important;
    background-color: #ffffff !important;
  }
`;

class Flights extends React.Component {
  state = {
    sortCol: 'Flight No.',
    sortDir: 'ascending',
    search: {
      Lastname: '',
      DateOfBirth: '',
      PNR: '',
      Flight: '',
      Date: '',
    },
    searchError: false,
    activeTab: 0,
  };

  handleSort = clickedColumn => () => {
    const { sortCol, sortDir } = this.state;

    if (sortCol !== clickedColumn) {
      this.setState({
        sortCol: clickedColumn,
        sortDir: 'ascending',
      });
      return;
    }

    this.setState({
      sortDir: sortDir === 'ascending' ? 'descending' : 'ascending',
    });
  };

  handleInputChange = (e, { name, value }) => this.setState({ search: { ...this.state.search, [name]: value } });

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeTab: activeIndex });
  };

  handleSeach = () => {
    const search = Object.assign({}, this.state.search);

    if (this.state.activeTab === 0) {
      search.Flight = '';
      search.Date = '';
    } else {
      search.PNR = '';
    }

    if (!search.PNR.trim()) {
      if (!search.Flight.trim() || !search.Date.trim()) {
        return this.setState({
          searchError:
            'Please provide a combination of Last Name, Date of Birth and Booking Reference or a combination of Last Name, Date of Birth, Flight No. and Flight Date.',
        });
      }
      delete search.PNR;
      const flightDate = moment(search.Date, 'DD MMM YYYY', true);
      if (!flightDate.isValid()) {
        return this.setState({
          searchError:
            'Please provide a valid Flight Date in the format "DD MMM YYYY" or simply select the date on the picker',
        });
      }
      search.Date = flightDate.format('YYYY-MM-DD');
    } else {
      delete search.Flight;
      delete search.Date;
    }

    const dateOfBirth = moment(search.DateOfBirth, 'DD MMM YYYY', true);
    if (!dateOfBirth.isValid()) {
      return this.setState({
        searchError:
          'Please provide a valid Date Of Birth in the format "DD MMM YYYY" or simply select the date on the picker',
      });
    }
    search.DateOfBirth = dateOfBirth.format('YYYY-MM-DD');

    this.setState({ searchError: false }, () => this.props.loadFlights(search));
  };

  renderFlights(flights) {
    const { loading, error } = this.props;
    if (loading) {
      return <PlaceHolderCard loading={true} />;
    }

    if (error) {
      let mess = error.message;
      if (mess === 'NO_DISRUPTION_FOUND') {
        mess =
          "Sorry, there were records found for the combination provided but we're unable to fetch operational information.";
      }
      return <PlaceHolderCard iconName="exclamation triangle" text={mess} />;
    }

    if (flights === false) {
      return <PlaceHolderCard iconName="search" text="Please fill out the form above." />;
    }

    if (flights.length === 0) {
      return (
        <PlaceHolderCard
          iconName="exclamation triangle"
          text="Sorry, there are no matching records for the combination provided."
        />
      );
    }

    const { sortCol, sortDir } = this.state;

    if (sortCol === 'NTDLocal') {
      flights = orderBy(flights, item => (item.NTDLocal ? item.NTDLocal : item.ETDLocal), [
        sortDir === 'ascending' ? 'asc' : 'desc',
      ]);
    } else {
      flights = orderBy(flights, [sortCol], [sortDir === 'ascending' ? 'asc' : 'desc']);
    }

    return (
      <FlightTable basic sortable selectable padded>
        <Table.Header>
          <Table.Row>
            <SortableTableHeaderCell sorted={sortCol === 'Flight' ? sortDir : null} onClick={this.handleSort('Flight')}>
              Flight No.
            </SortableTableHeaderCell>
            <SortableTableHeaderCell sorted={sortCol === 'Dep' ? sortDir : null} onClick={this.handleSort('Dep')}>
              Departure
            </SortableTableHeaderCell>
            <SortableTableHeaderCell sorted={sortCol === 'Arr' ? sortDir : null} onClick={this.handleSort('Arr')}>
              Arrival
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              sorted={sortCol === 'STDLocal' ? sortDir : null}
              onClick={this.handleSort('STDLocal')}
            >
              Original Departure
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              sorted={sortCol === 'NTDLocal' ? sortDir : null}
              onClick={this.handleSort('NTDLocal')}
            >
              New Time of Departure
            </SortableTableHeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {flights.map((flight, idx) => (
            <FlightRow key={flight.FlightFollowID + idx.toString()} data={flight} />
          ))}
        </Table.Body>
      </FlightTable>
    );
  }

  render() {
    const { flights, loading } = this.props;
    const { search, searchError, activeTab } = this.state;
    const today = moment().format('DD MMM YYYY');
    const birthday = moment()
      .subtract(30, 'years')
      .format('DD MMM YYYY');

    const bookingPane = (
      <TabPane inverted>
        <Segment basic loading={loading}>
          <Form.Group>
            <Form.Input
              required
              label="Last Name / Family Name"
              placeholder="Last Name as reflected on your booking"
              width={6}
              name="Lastname"
              value={search.Lastname}
              onChange={this.handleInputChange}
            />
            {/* <Responsive as={Divider} minWidth={Responsive.onlyTablet.minWidth} /> */}
            <Form.Field required width={4}>
              <label>Date of Birth</label>
              <DateInput
                closable={true}
                placeholder={'Date i.e ' + birthday}
                iconPosition="right"
                dateFormat="DD MMM YYYY"
                required={true}
                autoComplete="off"
                startMode="year"
                name="DateOfBirth"
                value={search.DateOfBirth}
                onChange={this.handleInputChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              label="Booking Reference"
              placeholder="Six character alphanumeric code"
              width={6}
              value={search.PNR}
              name="PNR"
              onChange={this.handleInputChange}
            />
          </Form.Group>
          {searchError ? <Message error content={searchError} /> : null}
          <Form.Group>
            <SubmitButton>
              Get Flight Status
              <Icon name="plane" />
            </SubmitButton>
          </Form.Group>
        </Segment>
      </TabPane>
    );

    const flightPane = (
      <TabPane inverted>
        <Segment basic loading={loading}>
          <Form.Group>
            <Form.Input
              required
              label="Last Name / Family Name"
              placeholder="Last Name as reflected on your booking"
              width={6}
              name="Lastname"
              value={search.Lastname}
              onChange={this.handleInputChange}
            />
            {/* <Responsive as={Divider} minWidth={Responsive.onlyTablet.minWidth} /> */}
            <Form.Field required width={4}>
              <label>Date of Birth</label>
              <DateInput
                closable={true}
                placeholder={'Date i.e ' + birthday}
                iconPosition="right"
                dateFormat="DD MMM YYYY"
                required={true}
                autoComplete="off"
                startMode="year"
                name="DateOfBirth"
                value={search.DateOfBirth}
                onChange={this.handleInputChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              label="Flight Number"
              placeholder="Flight Number i.e. 123"
              width={6}
              value={search.Flight}
              name="Flight"
              onChange={this.handleInputChange}
            />
            <Form.Field width={4} required>
              <label>Flight Date</label>
              <DateInput
                required
                closable={true}
                placeholder={'Date i.e ' + today}
                iconPosition="right"
                dateFormat="DD MMM YYYY"
                autoComplete="off"
                name="Date"
                value={search.Date}
                onChange={this.handleInputChange}
              />
            </Form.Field>
          </Form.Group>
          {searchError ? <Message error content={searchError} /> : null}
          <Form.Group>
            <SubmitButton>
              Get Flight Status
              <Icon name="plane" />
            </SubmitButton>
          </Form.Group>
        </Segment>
      </TabPane>
    );

    const searchPanes = [
      { menuItem: 'Search by Booking', render: () => bookingPane },
      { menuItem: 'Search by Flight', render: () => flightPane },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>Flights</title>
          <meta name="description" content="List of Flights" />
        </Helmet>
        <Header as="h2">
          <FlightIcon />
          Flight Status
        </Header>
        <SearchForm onSubmit={this.handleSeach} error={!!searchError}>
          <SearchTab
            menu={{ attached: true, tabular: true }}
            panes={searchPanes}
            activeIndex={activeTab}
            onTabChange={this.handleTabChange}
          />
        </SearchForm>
        <p>Note: Delay notices will only be updated during significant delays.</p>
        <FlightsListHeader>
          Flights
          {/* <FlightsListSubheader>
            Last updated on
            <br />
            Mon, Feb 26 10:54:27 (Singapore Time GMT +08)
          </FlightsListSubheader> */}
        </FlightsListHeader>
        <FlightsContainer>{this.renderFlights(flights)}</FlightsContainer>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  loadFlights: loadFlights,
};

const mapStateToProps = createStructuredSelector({
  flights: makeSelectFlights(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'flights', reducer });
const withSaga = injectSaga({ key: 'flights', saga });

export default compose(withReducer, withSaga, withConnect)(Flights);
