import React from 'react';
//import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
//import { NavLink } from 'react-router-dom';

//import messages from './messages';

const StyledMenu = styled(Menu)`
  .ui& {
    height: 56px;
    background-color: #ffe900;
    border: none;

    background-image: url(https://cdn.flyscoot.com/prod/images/default-source/assets/scoot-logo.svg);
    background-repeat: no-repeat;
    background-position: left -10px center;
    background-size: 90px;
  }

  && .active.item {
    background: none;
    font-weight: bold;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class Header extends React.Component {
  render() {
    return <StyledMenu fixed="top" borderless stackable />;
  }
}

export default Header;
