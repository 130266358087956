/*
 * App Global Actions
 */

import { FETCH_START, FETCH_END, FETCH_ERROR, FETCH_CANCEL } from './constants';

export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });
export const fetchError = () => ({ type: FETCH_ERROR });
export const fetchCancel = () => ({ type: FETCH_CANCEL });
