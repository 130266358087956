import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFlightsPage = state => state.get('flights', initialState);

export const makeSelectFlights = () =>
  createSelector(
    selectFlightsPage,
    state => state.get('flights'),
  );

export const makeSelectLoading = () =>
  createSelector(
    selectFlightsPage,
    state => state.get('loading'),
  );

export const makeSelectError = () =>
  createSelector(
    selectFlightsPage,
    state => state.get('error'),
  );
