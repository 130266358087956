/*
 * Flights Actions
 */

import { LOAD_FLIGHTS, LOAD_FLIGHTS_SUCCESS, LOAD_FLIGHTS_ERROR } from './constants';

export function loadFlights(payload) {
  return {
    type: LOAD_FLIGHTS,
    payload,
  };
}

export function flightsLoaded(flights) {
  return {
    type: LOAD_FLIGHTS_SUCCESS,
    flights,
  };
}

export function flightLoadingError(error) {
  return {
    type: LOAD_FLIGHTS_ERROR,
    error,
  };
}
