import React, { Component } from 'react';
import styled from 'styled-components';
import { Header, Table, Icon, Responsive } from 'semantic-ui-react';
import moment from 'moment';
import { withRouter } from 'react-router';

const TableRow = styled(Table.Row)`
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const FlightNumberHeader = styled(Header)`
  font-weight: bold;
  font-size: 14px;
  /* font-family: 'ITC Avant Garde Gothic'; */
`;

const AirportCodeHeader = styled(Header)`
  font-weight: 500;
  font-size: 14px;
  /* font-family: 'ITC Avant Garde Gothic'; */
`;

const ViewIcon = styled(Icon).attrs({ name: 'angle right' })`
  i&.icon {
    margin: 0 0 0 10px;
    font-size: 20px;
    vertical-align: middle;
    line-height: 17px;
    width: 17px;
    float: right;
  }
`;

const AnnouncementText = styled.span`
  color: ${props => (props.count && props.count > 0 ? '#da1c25' : '#b2b2b2')};
  font-weight: bold;
  font-size: 14px;
  @media only screen and (min-width: ${Responsive.onlyTablet.minWidth}px) {
    float: right;
  }
`;

class FlightRow extends Component {
  parseDateTime(depDateTime, newDepDateTime) {
    const dep = moment(depDateTime);
    const depDate = dep.format('DD MMM YYYY');
    const depTime = dep.format('HH:mm');
    let newDep;
    if (!newDepDateTime) {
      newDep = dep.clone();
    } else {
      newDep = moment(newDepDateTime);
    }
    const newDepDate = newDep.format('DD MMM YYYY');
    const newDepTime = newDep.format('HH:mm');

    return [depDate, depTime, newDepDate, newDepTime];
  }

  renderAnnouncementText(numAnns) {
    if (numAnns === 0) {
      return <AnnouncementText>No significant delay reported.</AnnouncementText>;
    } else {
      return (
        <AnnouncementText count={numAnns}>{numAnns + ' Announcement' + (numAnns !== 1 ? 's' : '')}</AnnouncementText>
      );
    }
  }

  handleClick = record => () => {
    this.props.history.push('/flights/' + record.FlightFollowID);
  };

  render() {
    const { data = {} } = this.props;

    // parse data
    const [stdDate, stdTime, ntdDate, ntdTime] = this.parseDateTime(
      data.STDLocal,
      data.NTDLocal ? data.NTDLocal : data.ETDLocal,
    );
    const numAnns = data.Announcements ? data.Announcements.length : 0;

    return (
      <TableRow onClick={numAnns > 0 ? this.handleClick(data) : undefined}>
        <Table.Cell>
          <FlightNumberHeader as="h4">
            {data.Designator} {data.Flight}
          </FlightNumberHeader>
        </Table.Cell>
        <Table.Cell>
          <AirportCodeHeader as="h4">{data.Dep}</AirportCodeHeader>
        </Table.Cell>
        <Table.Cell>
          <AirportCodeHeader as="h4">{data.Arr}</AirportCodeHeader>
        </Table.Cell>
        <Table.Cell>
          {stdDate}
          <Responsive as={'span'} maxWidth={Responsive.onlyMobile.maxWidth}>
            &nbsp;&nbsp;&nbsp;
          </Responsive>
          <Responsive as={'br'} minWidth={Responsive.onlyTablet.minWidth} />
          {stdTime}
        </Table.Cell>
        <Table.Cell>
          {ntdDate}
          <Responsive as={'span'} maxWidth={Responsive.onlyMobile.maxWidth}>
            &nbsp;&nbsp;&nbsp;
          </Responsive>
          <Responsive as={'br'} minWidth={Responsive.onlyTablet.minWidth} />
          {ntdTime}
        </Table.Cell>
        <Table.Cell>
          {numAnns > 0 ? <ViewIcon /> : null}
          {this.renderAnnouncementText(numAnns)}
        </Table.Cell>
      </TableRow>
    );
  }
}

export default withRouter(FlightRow);
