/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import { FETCH_START, FETCH_END, FETCH_ERROR, FETCH_CANCEL } from './constants';

// The initial state of the App
let initialState = fromJS({
  loading: 0,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_START:
      return state.set('loading', state.get('loading') + 1);
    case FETCH_END:
    case FETCH_ERROR:
    case FETCH_CANCEL:
      return state.set('loading', Math.max(state.get('loading') - 1, 0));
    default:
      return state;
  }
}

export default appReducer;
