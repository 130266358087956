/*
 * FlightsReducer
 */

import { fromJS } from 'immutable';

import { LOAD_FLIGHTS, LOAD_FLIGHTS_SUCCESS, LOAD_FLIGHTS_ERROR } from './constants';

// The initial state of the Flights
export const initialState = fromJS({
  error: false,
  loading: false,
  flights: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FLIGHTS:
      return state.set('error', false).set('loading', true);
    case LOAD_FLIGHTS_SUCCESS:
      return state.set('flights', action.flights).set('loading', false);
    case LOAD_FLIGHTS_ERROR:
      return state.set('error', action.error).set('loading', false);
    default:
      return state;
  }
}

export default appReducer;
