/**
 * Gets the flightsitories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import { LOAD_FLIGHTS } from 'containers/Flights/constants';
import { flightsLoaded, flightLoadingError } from 'containers/Flights/actions';
import { fetchError, fetchStart, fetchEnd } from 'containers/App/actions';

import request from 'utils/request';

/**
 * Github flights request/response handler
 */
export function* getFlights(action) {
  const requestURL = `${process.env.REACT_APP_API_URL}/flights`;

  try {
    yield put(fetchStart());
    let reqOpts = {
      method: 'POST',
      body: JSON.stringify(action.payload),
    };
    const resp = yield call(request, requestURL, reqOpts);
    let flights = [];
    if (resp.json && resp.json.data) {
      flights = resp.json.data;
    }
    yield put(flightsLoaded(flights));
    yield put(fetchEnd());
  } catch (err) {
    yield put(flightLoadingError(err));
    yield put(fetchError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* flightsData() {
  yield takeLatest(LOAD_FLIGHTS, getFlights);
}
