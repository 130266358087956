class HttpError extends Error {
  constructor(message, status, body = null) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers();
  }
  if (!options.headers.has('Accept')) {
    options.headers.set('Accept', 'application/json');
  }
  if (!options.headers.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
    options.headers.set('Content-Type', 'application/json');
  }
  if (!!localStorage.getItem('token')) {
    let token;
    try {
      token = JSON.parse(localStorage.getItem('token'));
      options.headers.set('Authorization', `Bearer ${token.access_token}`);
    } catch (e) {
      // no big deal
    }
  }
  return fetch(url, options)
    .then(response => {
      return response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }));
    })
    .then(({ status, statusText, headers, body }) => {
      let json = {};
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        let errMess = json.message || statusText;
        if (json.error && json.error.message) {
          errMess = json.error.message;
        }
        return Promise.reject(new HttpError(errMess, status, json));
      }
      return Promise.resolve({ status, headers, body, json });
    });
};
