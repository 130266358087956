import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Loader, Header, Icon } from 'semantic-ui-react';

const CardWrapper = styled(Card)`
  &.ui.card {
    padding: 25px 30px;
    border-radius: 6px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.06);
    min-height: 196px;
    background: #f9fafb;
    & > .content {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const PlaceHolderCard = ({ fluid, loading, iconName, text }) => {
  loading = !iconName;
  return (
    <CardWrapper fluid={fluid}>
      <Card.Content>
        {loading ? <Loader active>{text ? text : null}</Loader> : null}
        {iconName ? (
          <Header icon>
            <Icon name={iconName} />
            {text ? text : null}
          </Header>
        ) : null}
      </Card.Content>
    </CardWrapper>
  );
};

PlaceHolderCard.defaultProps = {
  fluid: true,
  loading: false,
  iconName: '',
  text: '',
};

PlaceHolderCard.propTypes = {
  fluid: PropTypes.bool,
  loading: PropTypes.bool,
  iconName: PropTypes.string,
  text: PropTypes.string,
};

export default PlaceHolderCard;
